<template>
  <no-sidebar-layout v-if="isDataLoaded">
    <v-container class="px-6 px-sm-0 px-xl-9 pt-0 pb-6">
      <v-row class="my-0" justify="center">
        <v-col cols="12" class="py-0">
          <img :src="project.banner">
          <h1 class="my-4" v-text="`${$t('common.hello')}`" />
          <p class="mt-5 text-h5 font-weight-light">
            {{ $t('club.clubEnrollmentForm.intro.registerYourTeams', {nameProject: project.name}) }}
          </p>
          <p class="mt-9">
            {{ $t('club.clubEnrollmentForm.intro.dataRequestedRegister') }}
          </p>
          <div class="mt-9">
            <v-chip class="mr-2" color="primary">
              1
            </v-chip><strong>{{ $t('club.clubEnrollmentForm.intro.clubData') }}</strong>
            {{ $t('club.clubEnrollmentForm.intro.needSomeDataToKnowYou') }}
          </div>
          <div class="mt-9">
            <v-chip class="mr-2" color="primary">
              2
            </v-chip>
            <strong>{{ $t('club.clubEnrollmentForm.intro.addTeams') }}</strong>
            {{ $t('club.clubEnrollmentForm.intro.addTeamsDescription') }}
          </div>
          <div class="mt-9">
            <v-chip class="mr-2" color="primary">
              3
            </v-chip>
            <strong>{{ $t('club.clubEnrollmentForm.intro.detailsClubStaff') }}</strong>
            {{ $t('club.clubEnrollmentForm.intro.detailsClubStaffDescription') }}
          </div>
          <div class="mt-9">
            {{ $t('club.clubEnrollmentForm.intro.onceRegistrationBeenSent') }}
          </div>
          <div class="mt-12">
            <v-checkbox v-model="privacyOwqlo">
              <template #label>
                <span @click.stop v-html="$t('common.acceptTermsAndPrivacyPolicyOwqlo')" />
              </template>
            </v-checkbox>
            <v-checkbox v-model="privacyProject">
              <template #label>
                <span @click.stop v-html="$t('common.acceptPrivacyPolicyCompany', { url: template.privacyPolicyUrl, company: project.name })" />
              </template>
            </v-checkbox>
          </div>
          <div class="d-flex justify-center">
            <v-btn :disabled="!privacyOwqlo || !privacyProject" :to="{ name: 'club-enrollment-form-step-1', params: { userComesFromIntro: true } }"
                   color="primary" class="mt-9" v-text="$t('common.start')"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </no-sidebar-layout>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IntroView',
  components: {
    NoSidebarLayout: () => import('@/components/NoSidebarLayout'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return { isDataLoaded: false, privacyOwqlo: false, privacyProject: false }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters('clubEnrollmentForm', ['template']),
  },
  async created() {
    const { organizationId, projectId } = this.$route.params
    this.$store.commit('loader/show')
    if (this.project?.id !== projectId) await this.$store.dispatch('project/bind', { organizationId, projectId })
    await this.$store.dispatch('clubEnrollmentForm/bindTemplate', { organizationId, projectId })
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
}
</script>
